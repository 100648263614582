import AnastasiiaMatIMG from "./SpeakersIMG/MatushevskaIMG.webp";
import SvitlanaMarIMG from "./SpeakersIMG/MarchekIMG.webp";
import BaevaIMG from "./SpeakersIMG/BaevaIMG.webp";
import HayvoronskaIMG from "./SpeakersIMG/HayvoronskaIMG.webp";
import ZadoroznyaIMG from "./SpeakersIMG/ZadoroznyaIMG.webp";
import DiveevaIMG from "./SpeakersIMG/DiveevaIMG.webp";
import ZimovinIMG from "./SpeakersIMG/ZimovinIMG.webp";
import PavluchokIMG from "./SpeakersIMG/PavluchokIMG.webp";
import AdelyaIMG from "./SpeakersIMG/IslamhulovaIMG.webp";
import DerhausovaIMG from "./SpeakersIMG/DerhausovaIMG.webp";
import ZoriyIMG from "./SpeakersIMG/ZoriyIMG.webp";
import HorobecIMG from "./SpeakersIMG/HorobecIMG.webp";
import PrudnikovaIMG from "./SpeakersIMG/PrudnikovaIMG.webp";
import NesvitIMG from "./SpeakersIMG/NesvitIMG.webp";
import KozachkovaIMG from "./SpeakersIMG/KozachkovaIMG.webp";
import SolohubIMG from "./SpeakersIMG/SolohubIMG.webp";
import AdamovaIMG from "./SpeakersIMG/AdamovaIMG.webp";
import SozanovskaIMG from "./SpeakersIMG/MatveevaIMG.webp";
import NazaremkoIMG from "./SpeakersIMG/NazarenkoIMG.webp";
import IshkovaIMG from "./SpeakersIMG/IshkovaIMG.webp";
import LevchenkoIMG from "./SpeakersIMG/LevchenkoIMG.webp";
import VatrichIMG from "./SpeakersIMG/VatrichIMG.webp";

export const speakersInfoCare = [
  {
    speakerIMG: AnastasiiaMatIMG,
    speakerName: "Анастасія-Олімпія Матюшевська",
    speakerInstagram:
      "https://www.instagram.com/anastasia.olimpia.matushevska?igsh=MWZlbGxvd2wzOHEw",
    width: "555",
    height: "555",
    speakerTema: "Тема спікера зʼявиться наближчим часом",
    speakerAbout: [
      "Гештальт-терапевт НАГТУ",
      "Супервізор",
      "Акредитований член EAGT",
      //   "Спеціаліст по роботі з сексуальною тематикою",
      //   "Засновниця проектів психологічно-емоційного розвитку «ЖІНКИ»: конференції, ретрит",
    ],
  },
  {
    speakerIMG: SvitlanaMarIMG,
    speakerName: "Світлана Марчек",
    speakerInstagram:
      "https://www.instagram.com/svitlana_marcek?igsh=aTNvNTh5NHM0eGxl",
    width: "555",
    height: "555",
    speakerTema: "Тема спікера зʼявиться наближчим часом",
    speakerAbout: [
      "Гештальт-терапевт",
      "Aрт-терапевт",
      "Травматерапевт",
      "Спеціаліст з роботи з шоковими травмами, втратами, ПТСР та КПТСР",
    ],
  },
  {
    speakerIMG: BaevaIMG,
    speakerName: "Олена Баєва",
    speakerInstagram:
      "https://www.instagram.com/fannie_flagg?igsh=aWlpbDJjZDhpdjl1",
    width: "555",
    height: "555",
    speakerTema: "Тема спікера зʼявиться наближчим часом",
    speakerAbout: [
      "Гештальт-терапевт",
      "Супервізор",
      "Провідний тренер НАГТУ",
      "Ведуча навчальних програм, спеціалізації 'Гештальт-терапія з дітьми та сім'єю', 'Теорії розвитку та вікові кризи в Гештальт-терапії'",
    ],
  },
  {
    speakerIMG: AdamovaIMG,
    speakerName: "Лєна Адамова",
    speakerInstagram:
      "https://www.instagram.com/adamova_elena__?igsh=MTI5MnZ2M2hsOHZwZQ==",
    width: "555",
    height: "555",
    speakerTema: "Тема спікера зʼявиться наближчим часом",
    speakerAbout: [
      "Кандидат юридичних наук",
      "Психолог",
      "Психосоматолог",
      "Коуч-трансформатор",
      "Коуч-трансформатор рубрики «Жити легко з Адамовою» на ТРК Україна",
    ],
  },
  {
    speakerIMG: KozachkovaIMG,
    speakerName: "Юлія Козачкова",
    speakerInstagram:
      "https://www.instagram.com/kozachkova_yuliia?igsh=M3czeG0zMjZ3Mzlq",
    width: "555",
    height: "555",
    speakerTema: "Тема спікера зʼявиться наближчим часом",
    speakerAbout: [
      "Психолог-практик",
      "Коуч",
      "майстер НЛП",
      "фасилітатор ТоР Owner додатку Teya та Teya Shop",
      "Co-creator «Kozachkova club»",
      "Автор серії книг «Мистецтво бути в ЦЕЙ:ЧАС»",
      "Co-owner маркетингової агенції «Спокусливий бренд»",
    ],
  },
  {
    speakerIMG: DiveevaIMG,
    speakerInstagram: "https://www.instagram.com/olga_diveyeva_/",
    speakerName: "Ольга Дівєєва",
    width: "555",
    height: "555",
    speakerTema: "Тема спікера зʼявиться наближчим часом",
    speakerAbout: [
      "Клінічний психолог",
      "Експерт з психосоматики",
      "Авторка та ведуча марафонів, вебінарів ",
    ],
  },
  {
    speakerIMG: AdelyaIMG,
    speakerName: "Аделя Ісламгулова",
    speakerInstagram: "https://www.instagram.com/adelia.islamhulova/",
    width: "555",
    height: "555",
    speakerTema: "Тема спікера зʼявиться наближчим часом",
    speakerAbout: [
      "Ментор по фінансовому мисленню",
      "власниця G.Bar Limassol",
      "Співзасновниця @heldelaconstruction",
    ],
  },
];

export const speakersInfoFlirt = [
  {
    speakerIMG: AnastasiiaMatIMG,
    speakerName: "Анастасія-Олімпія Матюшевська",
    speakerInstagram:
      "https://www.instagram.com/anastasia.olimpia.matushevska?igsh=MWZlbGxvd2wzOHEw",
    width: "585",
    height: "585",
    speakerTema: "Бажання дражнити, щоб вас піддразнювали",
    speakerAbout: ["Гештальт-терапевт НАГТУ, сексолог"],
  },
  {
    speakerIMG: BaevaIMG,
    speakerName: "Олена Баєва",
    speakerInstagram:
      "https://www.instagram.com/fannie_flagg?igsh=aWlpbDJjZDhpdjl1",
    width: "585",
    height: "585",
    speakerTema:
      "Театр жіночого життя: гра як форма розвитку, атмосфера гри, жіночі ігри",
    speakerAbout: ["Гештальт-терапевт, супервізор, провідний тренер НАГТУ"],
  },
  {
    speakerIMG: SvitlanaMarIMG,
    speakerName: "Світлана Марчек",
    speakerInstagram:
      "https://www.instagram.com/svitlana_marcek?igsh=aTNvNTh5NHM0eGxl",
    width: "585",
    height: "585",
    speakerTema: "Флірт як втеча від близькості",
    speakerAbout: ["Гештальт-терапевт, арт-терапевт, травматерапевт"],
  },
  {
    speakerIMG: PrudnikovaIMG,
    speakerName: "Вікторія Пруднікова",
    speakerInstagram:
      "https://www.instagram.com/prudnikovavictoria?igsh=eXZuOTZ1aWNjMm5v",
    width: "585",
    height: "585",
    speakerTema: "Аудит життя",
    speakerAbout: ["Експерт з ментальної генетики, консультант"],
  },
  {
    speakerIMG: NesvitIMG,
    speakerName: "Костянтин Несвіт",
    speakerInstagram:
      "https://www.instagram.com/knesvit?igsh=MXVobzZ0a29jYzB1OQ==",
    width: "585",
    height: "585",
    speakerTema: "Флірт як практична навичка. Елементи флірту та їх розвиток",
    speakerAbout: [
      "Чоловічий психоло, засновник «Чоловічого психологічного клубу»",
    ],
  },
  {
    speakerIMG: KozachkovaIMG,
    speakerName: "Юлія Козачкова",
    speakerInstagram:
      "https://www.instagram.com/kozachkova_yuliia?igsh=M3czeG0zMjZ3Mzlq",
    width: "585",
    height: "585",
    speakerTema: "Жіночність та гроші",
    speakerAbout: ["Психолог-практик, коуч, майстер НЛП"],
  },
  {
    speakerIMG: SolohubIMG,
    speakerName: "Ольга Сологуб",
    speakerInstagram:
      "https://www.instagram.com/olga.sologub?igsh=ZGcyNGk3M2dtNmg=",
    width: "585",
    height: "585",
    speakerTema: "Флірт як стиль життя",
    speakerAbout: ["Психолог, сексолог, тренер інституту MIGIS"],
  },
  {
    speakerIMG: AdamovaIMG,
    speakerName: "Лєна Адамова",
    speakerInstagram:
      "https://www.instagram.com/adamova_elena__?igsh=MTI5MnZ2M2hsOHZwZQ==",
    width: "585",
    height: "585",
    speakerTema: "Мова спілкування сексуальності",
    speakerAbout: ["Психолог, психосоматолог, коуч-трансформатор"],
  },
  {
    speakerIMG: PavluchokIMG,
    speakerName: "Наталія Павлючок",
    speakerInstagram: "https://www.instagram.com/natalia.pavluchok/",
    width: "585",
    height: "585",
    speakerTema: "Ідентичність та флірт",
    speakerAbout: ["Підприємниця, амбасадорка здорових сімейних відносин"],
  },
  {
    speakerIMG: HayvoronskaIMG,
    speakerName: "Юлія Гайворонская",
    speakerInstagram:
      "https://www.instagram.com/yuliya_gayvoronskaya?igsh=MXJmOXVmcGtpcTlveA==",
    width: "585",
    height: "585",
    speakerTema: "Як навчитися любити флірт та себе в ньому",
    speakerAbout: ["Секс коуч, тренер з сексуальної освіти"],
  },
  {
    speakerIMG: SozanovskaIMG,
    speakerName: "Леся Матвєєва-Созановська",
    speakerInstagram:
      "https://www.instagram.com/lesiamatveyeva?igsh=MXFudDZ0djc3MnB2Ng==",
    width: "585",
    height: "585",
    speakerTema: "Як підвищити самооцінку та стати впевненою у собі.",
    speakerAbout: ["Практичний психолог та психоаналітик"],
  },
];
export const speakersInfoProhibition = [
  {
    speakerIMG: AnastasiiaMatIMG,
    speakerName: "Анастасія-Олімпія Матюшевська",
    speakerInstagram:
      "https://www.instagram.com/anastasia.olimpia.matushevska?igsh=MWZlbGxvd2wzOHEw",
    width: "585",
    height: "585",
    speakerTema: "Кризи жіночої ідентичності як природнє порушення кордонів",
    speakerAbout: ["Гештальт-терапевт НАГТУ, сексолог"],
  },
  {
    speakerIMG: BaevaIMG,
    speakerName: "Олена Баєва",
    speakerInstagram:
      "https://www.instagram.com/fannie_flagg?igsh=aWlpbDJjZDhpdjl1",
    width: "585",
    height: "585",
    speakerTema: "Смак заборон",
    speakerAbout: ["Гештальт-терапевт, супервізор, провідний тренер НАГТУ"],
  },
  {
    speakerIMG: SvitlanaMarIMG,
    speakerName: "Світлана Марчек",
    speakerInstagram:
      "https://www.instagram.com/svitlana_marcek?igsh=aTNvNTh5NHM0eGxl",
    width: "585",
    height: "585",
    speakerTema:
      " «На захисті беззахисного». Вплив раннього травматичного досвіду на формування особистих кордонів.",
    speakerAbout: ["Гештальт-терапевт, арт-терапевт, травматерапевт"],
  },
  {
    speakerIMG: PavluchokIMG,
    speakerName: "Наталія Павлючок",
    speakerInstagram: "https://www.instagram.com/natalia.pavluchok/",
    width: "585",
    height: "585",
    speakerTema: "Ідентичність українок у сучасному світі після вторгнення",
    speakerAbout: ["Підприємниця, амбасадорка здорових сімейних відносин"],
  },
  {
    speakerIMG: ZimovinIMG,
    speakerName: "Олексій Зимовін",
    speakerInstagram:
      "https://www.instagram.com/psy_zimovin?igsh=aWZ1anluOGJtNDlj",
    speakerTema: "Від дільожки до обміну: жінки та чоловіки НА МЕЖІ",
    speakerAbout: ["Психолог, гештальт-терапевт, PhD"],
    width: "585",
    height: "585",
  },
  {
    speakerIMG: AdelyaIMG,
    speakerName: "Аделя Ісламгулова",
    speakerInstagram: "https://www.instagram.com/adelia.islamhulova/",
    speakerTema: "Кожній жінці потрібні її власні гроші",
    speakerAbout: ["Ментор по фінансовому мисленню, власниця G.Bar Limassol"],
    width: "585",
    height: "585",
  },
  {
    speakerIMG: DerhausovaIMG,
    speakerName: "Саша Дергоусова",
    speakerInstagram: "https://www.instagram.com/dergousova/",
    speakerTema: "Як досягають бажаного без боротьби, стресу та суєти.",
    speakerAbout: [
      "Бізнес-women, метор ментального здоровʼя, ведуча трансформер єдиної свідомості",
    ],
    width: "585",
    height: "585",
  },
  {
    speakerIMG: HayvoronskaIMG,
    speakerName: "Юлія Гайворонская",
    speakerInstagram:
      "https://www.instagram.com/yuliya_gayvoronskaya?igsh=MXJmOXVmcGtpcTlveA==",
    width: "585",
    height: "585",
    speakerTema: "Безпека у інтимних стосунках",
    speakerAbout: ["Секс коуч, тренер з сексуальної освіти"],
  },
  {
    speakerIMG: DiveevaIMG,
    speakerInstagram: "https://www.instagram.com/olga_diveyeva_/",
    speakerName: "Ольга Дівєєва",
    speakerTema:
      "ВИХІД - психологічний розбір діагнозів та складних ситуацій учасників",
    speakerAbout: ["Клінічний психолог, експерт з психосоматики"],
    width: "585",
    height: "585",
  },
  {
    speakerIMG: ZadoroznyaIMG,
    speakerName: "Веронiка Задорожна",
    speakerInstagram: "https://www.instagram.com/veronika.zadorozhnaya/",
    width: "585",
    height: "585",
    speakerTema: "Особисті кордони або чому з вами можно чинити погано!",
    speakerAbout: [
      "Цифровий психолог мільонерів, мотиваційний спікер, ментор, майстер НЛП",
    ],
  },
  {
    speakerIMG: ZoriyIMG,
    speakerName: "Алла Зорій",
    speakerInstagram: "https://www.instagram.com/alla_zoriy/",
    width: "585",
    height: "585",
    speakerTema:
      "Як перепрограмувати свій мозок на досягнення своїх бажаних цілей",
    speakerAbout: ["Лікар, медичний психолог"],
  },
  {
    speakerIMG: HorobecIMG,
    speakerName: "Марися Горобець",
    speakerInstagram: "https://www.instagram.com/marysyagorobets/",
    width: "585",
    height: "585",
    speakerTema:
      "Жіночі кордони та сила мережування: як порушувати правила, залишаючись вірною собі",
    speakerAbout: ["Амбасадор культурної дипломатії, бренд ментор"],
  },
];
export const prohibitionVideos = [
  {
    id: "MatushevskaProhibition",
    link: "https://www.youtube.com/embed/zLnQ2z-vUiU?si=TlgFEu8Cr9ve3SBX",
  },
  {
    id: "BaevaProhibition",
    link: "https://www.youtube.com/embed/YG9cpgGx9l0?si=d7IL1iZ8ZDaiFvMD",
  },
  {
    id: "MarchekProhibition",
    link: "https://www.youtube.com/embed/olCs03Nx9Ko?si=IAx2KLLVtbLXc2g1",
  },
  {
    id: "PavluchokProhibition",
    link: "https://www.youtube.com/embed/rNpDFFIdrWk?si=eN5eBmXiLF-4WPDT",
  },
  {
    id: "ZimovinProhibition",
    link: "https://www.youtube.com/embed/3ZvPbGTDvDE?si=H9-tNtEsc1l-uLOt",
  },
  {
    id: "IslamhulovaProhibition",
    link: "https://www.youtube.com/embed/P4FryPkSaPo?si=2-95eJsluslQ2yno",
  },
  {
    id: "DerhousovaProhibition",
    link: "https://www.youtube.com/embed/SqetLU28FOQ?si=SSECG3Ys6TZup7Xp",
  },
  {
    id: "HayvoronskaProhibition",
    link: "https://www.youtube.com/embed/xHHlyJRq7NU?si=bcGGj04vPSLBHbe2",
  },
  {
    id: "DiveevaProhibition",
    link: "https://www.youtube.com/embed/BSkKYZKoe-Q?si=ttya2HT29Jo2umaV",
  },
  {
    id: "ZadoroznaProhibition",
    link: "https://www.youtube.com/embed/dzcvs4_o8NA?si=ZKi1SP-CTi6XaeRp",
  },
  {
    id: "ZoriyProhibition",
    link: "https://www.youtube.com/embed/gOtmLS35sLg?si=n4TeFalIU2YuIDnY",
  },
  {
    id: "HorobecProhibition",
    link: "https://www.youtube.com/embed/XIeLhD7XiFI?si=he7RDFKnwzQH-14t",
  },
];

export const aggreSpeakersJSON = [
  {
    speakersIMG: NazaremkoIMG,
    speakersName: "Тетяна Назаренко",
    speakersTema:
      "Аутоагресія при складних стосунках з їжею та невдоволенні тілом",
    speakersAbout: "Клінічний психолог, супервізор",
    width: "585",
    height: "585",
  },
  {
    speakersIMG: DiveevaIMG,
    speakersName: "Ольга Дівєєва",
    speakersTema: "Соматизація жіночого здоровʼя: де живе агресія",
    speakersAbout: "Клінічний психолог, експерт з психосоматики",
    width: "585",
    height: "585",
  },
  {
    speakersIMG: AnastasiiaMatIMG,
    speakersName: "Анастасія-Олімпія Матюшевська",
    speakersTema: "Агресія, як провідний інструмент у розвитку жінки",
    speakersAbout: "Гештальт-терапевт, сексолог",
    width: "585",
    height: "585",
  },
  {
    speakersIMG: IshkovaIMG,
    speakersName: "Наталія Ішкова",
    speakersTema: "Жінка, як держава на карті людства",
    speakersAbout:
      "Психотерапевт у транзактному аналізі, гештальті та сексології",
    width: "585",
    height: "585",
  },
  {
    speakersIMG: PrudnikovaIMG,
    speakersName: "Вікторія Пруднікова",
    speakersTema:
      "Аудити життя (інтерактив з виявлення підсвідомих причин агресивних патернів)",
    speakersAbout: "Експерт з ментальної генетики, консультант",
    width: "585",
    height: "585",
  },
  {
    speakersIMG: SvitlanaMarIMG,
    speakersName: "Світлана Марчек",
    speakersTema: "Бити чи не бити? Що впливає на вибір?",
    speakersAbout: "Гештальт-терапевт, травматерапевт, арт-терапевт",
    width: "585",
    height: "585",
  },
  {
    speakersIMG: AdamovaIMG,
    speakersName: "Лена Адамова",
    speakersTema: "Руйнівні та пригнічені способи жити своє життя",
    speakersAbout: "Психолог, психосоматолог, коуч-трансформатор",
    width: "585",
    height: "585",
  },
  {
    speakersIMG: PavluchokIMG,
    speakersName: "Наталія Павлючок",
    speakersTema: "Жінка лідерка та агресія",
    speakersAbout: "Підприємниця, амбасадорка здорових сімейних відносин",
    width: "585",
    height: "585",
  },
  {
    speakersIMG: LevchenkoIMG,
    speakersName: "Василіса Левченко",
    speakersTema: "Життя сильної жінки в світі стереотипів про «слабку стать»",
    speakersAbout: "Психотерапевт, військовий волонтер",
    width: "585",
    height: "585",
  },
  {
    speakersIMG: ZimovinIMG,
    speakersName: "Олексій Зимовін",
    speakersTema: "Чоловік у полі жіночої агресії",
    speakersAbout: "Гештальт-терапевт, психолог, PhD",
    width: "585",
    height: "585",
  },
  {
    speakersIMG: BaevaIMG,
    speakersName: "Олена Баєва",
    speakersTema: "Жіноча агресія: сили творення та руйнування",
    speakersAbout: "Гештальт-терапевт, супервізор, провідний тренер НАГТУ",
    width: "585",
    height: "585",
  },
  {
    speakersIMG: VatrichIMG,
    speakersName: "Ірина Ватрич",
    speakersTema: "Астрологічні показники агресії",
    speakersAbout: "Астролог",
    width: "585",
    height: "585",
  },
];
