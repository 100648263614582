import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// CHANGE TO WOMENPLACE http://127.1.2.205:3000 https://www.women.place
axios.defaults.baseURL = "https://www.women.place";
axios.defaults.withCredentials = true;

export const getUserData = createAsyncThunk("userdata", async (_, thunkAPI) => {
  try {
    console.log("userdata req");
    const response = await axios.get("/userdata");

    return response.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});
