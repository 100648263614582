import style from "./AggreSpeakers.module.css";
import sprite from "../../../icons.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { aggreSpeakersJSON } from "../../DBSVI/DBSVI";

export default function AggreSpeakers() {
  
  return (
    <section className={style.aggreSpeakersSection} id="speakersSection">
      <div className="container">
        <h2 className={style.titleAggreSpeakers}>/спікери конференції</h2>
        <ul className={style.wrapperAggreSpeakersInfo}>
          {aggreSpeakersJSON.map((speakersCard) => {
            return (
              <li
                className={style.aggreSpeakerCard}
                key={speakersCard.speakersName}
              >
                <img
                  className={style.aggrespeakerIMG}
                  width={speakersCard.width}
                  height={speakersCard.height}
                  src={speakersCard.speakersIMG}
                  alt={speakersCard.speakersName}
                  loading="lazy"
                />
                <p className={style.aggreSpeakerNameOnCard}>
                  {speakersCard.speakersName}
                </p>
                <h3 className={style.aggreTitleSpeakerTema}>тема виступу:</h3>
                <p className={style.agreeTextSpeakerTema}>
                  {speakersCard.speakersTema}
                </p>
                <h4 className={style.aggreTitleSpeakerTema}>про спікера:</h4>
                <p className={style.agreeTextSpeakerAbout}>
                  {speakersCard.speakersAbout}
                </p>
                <span className={style.aggreEllipseUnderSpeakerIMG}></span>
              </li>
            );
          })}
        </ul>

        <div className={style.mySwiperAggrePageSpeakersNavigation}>
          <div className={style.swiperButtonPrevAggre}>
            <svg
              onClick={(e) => e.preventDefault()}
              className=""
              width={60}
              height={60}
              fill="white"
            >
              <use xlinkHref={`${sprite}#arrow-right`}></use>
            </svg>
          </div>
          <div className={style.swiperButtonNextAggre}>
            <svg
              onClick={(e) => e.preventDefault()}
              className=""
              width={60}
              height={60}
              fill="white"
            >
              <use xlinkHref={`${sprite}#arrow-left`}></use>
            </svg>
          </div>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={40}
          pagination={{
            type: "fraction",
          }}
          navigation={{
            prevEl: `.${style.swiperButtonPrevAggre}`,
            nextEl: `.${style.swiperButtonNextAggre}`,
          }}
          modules={[Pagination, Navigation]}
          autoHeight={true}
          className={`${style.mySwiperAggrePageSpeakers} ${style.reset}`}
        >
          {aggreSpeakersJSON.map((speakersCard) => {
            return (
              <SwiperSlide
                key={speakersCard.speakersName}
                className={style.swiperSlide}
              >
                <img
                  className={style.aggrespeakerIMG}
                  width={speakersCard.width}
                  height={speakersCard.height}
                  src={speakersCard.speakersIMG}
                  alt={speakersCard.speakersName}
                />
                <p className={style.aggreSpeakerNameOnCard}>
                  {speakersCard.speakersName}
                </p>
                <h3 className={style.aggreTitleSpeakerTema}>тема виступу:</h3>
                <p className={style.agreeTextSpeakerTema}>
                  {speakersCard.speakersTema}
                </p>
                <h4 className={style.aggreTitleSpeakerTema}>про спікера:</h4>
                <p className={style.agreeTextSpeakerAbout}>
                  {speakersCard.speakersAbout}
                </p>
                <span className={style.aggreEllipseUnderSpeakerIMG}></span>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
}
