import style from "./ProhibitionReviews.module.css";

import { Scrollbar, Virtual } from "swiper/modules";
import { useState, useEffect } from "react";

import "swiper/css";
import "swiper/css/scrollbar";
import { SwiperSlide, Swiper } from "swiper/react";

export default function ProhibitionReviews() {
  const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView());

  useEffect(() => {
    function handleResize() {
      setSlidesPerView(getSlidesPerView());
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function getSlidesPerView() {
    return window.innerWidth >= 455 ? 2 : 1;
  }

  const prohibitionReviewsJSON = [
    {
      reviewerName: "Ольга Юрьина",
      reviewerComment:
        "Добрый вечер) была на конференции, многих спикеров видела впервые и мне очень понравилось! Вы замечательный организатор и оратор)) С удовольствием поучаствую ещё ☺️",
    },
    {
      reviewerName: "Сабіна Мєсяцева",
      reviewerComment:
        "Дякую за можливість побути на цій конференції, вона була мені неочікувано потрібна і дуже допомогла🫶🏼",
    },
    {
      reviewerName: "Марина Божко",
      reviewerComment:
        "Хочу подякувати вам, команді і перш за все Насті за чудовий формат. Вчора отримала несамовите задоволення. Це раз дякую, що ви мене запросили. Спочатку обставини складалися так, що не виходило приєднатися і я не розглядала участь, а потім просто магічно все склалося і я змогла бути з вами, це любов.",
    },
    {
      reviewerName: "Христина Кукурудзяк",
      reviewerComment:
        "Доброго вечора! Дуже дякую за конференцію! Це було неймовірно!!! Нажаль не на всьому вдалось бути присутньою!",
    },
    {
      reviewerName: "Марина Кондратенко",
      reviewerComment:
        "Спасибо большое за лекцию. Восхищает и доставляет удовольствие профессиональный язык и глубина знаний, которыми вы готовы делиться. Отдельная благодарность за капельку околохристианских метафор, есть чувство некоторой загадки и открытий 🥰",
    },
    {
      reviewerName: "Анна Кругляк",
      reviewerComment:
        "Юля ви така потужна, такий фонтан енергії, дуже крута лекція, своєчасна для мене. Життєву енергію мені точно підняли, разом з лібідо🔥😍",
    },
    {
      reviewerName: "Мар’яна",
      reviewerComment:
        "Аделя, спасибо огромное за отрезвляющий и вдохновляющий эфир! Столько инсайтов и дельных советов. Благодарю💗",
    },
    {
      reviewerName: "Kateryna",
      reviewerComment:
        "Невероятная лекция 🔥🔥🔥 такая четкость и желание - ОТКРЫТЬ глаза и действовать🙏🏻 Благодарю!",
    },
    {
      reviewerName: "Nataliia Pavliuchok",
      reviewerComment:
        "Настя, це неймовірна конференція для Жінок. Такі потужні спікери. Ти робиш велику роботу.",
    },
    {
      reviewerName: "Anna A.",
      reviewerComment:
        "Це не просто конференція-це якесь суцільне естетичне світобачення! Барви жінок, поєднання аристократизму з вітальності. Я в захваті 🔥❤️‍🔥 і дякую Анастасія і команді, і жінкам за цей простір.",
    },
    {
      reviewerName: "Яна Тарасенко",
      reviewerComment:
        "Я слухала на одному диханні. Дуже глибоко, життєво та багато усвідомлення, які беру з собою! ❤️ Дякую, що об вас можна ставати новою версією своєї жінки.",
    },
    {
      reviewerName: "Marina Bozhko",
      reviewerComment:
        "Настя, дякую... ти підсвітила, як завжди, такі глибокі моменти адаптації в еміграції та взагалі в кризі, кризах... і сміюся, і плачу... дуже вдячна тобі за таку потужну тему.",
    },
    {
      reviewerName: "Марина",
      reviewerComment:
        "💗 Дякую за сміливість, силу голосу, за мотивацію до самопрояву та за неймовірну підтримку та солідарність. Окрема подяка за приклад з Винниченко!",
    },
    {
      reviewerName: "Яна",
      reviewerComment:
        "Мені здається, що всі хто попадає на розбори до Олі, вони всі щасливчики в житті☺️ А хто на навчанні та проходять всі продукти), то взагалі переживає весь спектр життєвих емоцій ☺️Життя До і Після!",
    },
    {
      reviewerName: "Анна Кругляк",
      reviewerComment:
        "Ольга просто высший пилотаж🔥 Девочки к вам много уважения за смелость и открытость❤️ Спасибо было ценно.",
    },
    {
      reviewerName: "Inna Gabriel",
      reviewerComment: "Это было безумно мощно!!! Спасибо.",
    },
    {
      reviewerName: "Оксана Ковач",
      reviewerComment:
        "Дуже вдячна за ваш виступ, приємно почути таку тему від чоловіка і ваша українська мова звучить дуже мелодійно, дякую 🙏🏻",
    },
    {
      reviewerName: "Marina",
      reviewerComment:
        "🔝 Дуже цінно було дослідити широку палітру та семантику кордонів та меж і окрема подяка за помежив'я. 👍Сьогодні отримала підтвердження, що незадоволенність - це крута ознака і що мати здорові динамічні межи у стосунках, відносинах плюс рівновага- це фундаментально. Безмежно вдячна 👏🏻",
    },
  ];

  return (
    <section className={style.sectionReviews} id="sectionReviews">
      <div className="container">
        <h2 className={style.titleReviews}>/відгуки учасників</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={slidesPerView}
          modules={[Scrollbar, Virtual]}
          scrollbar={{
            draggable: true,
          }}
          autoHeight={true}
          className={`${style.mySwiperReviews} ${style.reset}`}
        >
          {prohibitionReviewsJSON.map((aggreReviewsJSON, index) => (
            <SwiperSlide key={index}>
              <h3 className={style.titleNameReviewe}>
                {aggreReviewsJSON.reviewerName}
              </h3>
              <p className={style.commentFromReviewer}>
                {aggreReviewsJSON.reviewerComment}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
