import style from "./ProhibitionHero.module.css";
import sprite from "../../../icons.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import ChoiseProhibitionVideo from "../Modals/ChoiseProhibitionVideo";
import { MoreQuestions } from "../../HFM/Modals/Modules";
import { useState } from "react";

export default function ProhibitionHero() {
  const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false);
  const [prizeModalOpen, setPrizeModalOpen] = useState(false);

  return (
    <section className={style.aggreHeroSection} id="heroSection">
      <div className="container">
        <article className={style.wrapperAggreHero}>
          <div className={style.wrapperAggreHeroInformation}>
            <p className={style.numberOfConferenceAgree}>
              третiй з 11 етапів психоемоційного розвитку
            </p>
            <h2 className={style.titleAggrePage}>
              /КОРДОНИ ТА МЕЖІ <br /> Заборони ТА ЇХ ПОРУШЕННЯ
            </h2>
            <p className={style.textHeroWomen}>до жінок, біля жінок, жінками</p>
            <div className={style.wrapperButtonAgreeConference}>
              <button
                className="redBtn"
                onClick={() => setModalPrizeOpen(true)}
              >
                Придбати записи
              </button>
              <button
                className="transperentBtn"
                onClick={() => setPrizeModalOpen(true)}
              >
                Додаткові питання
              </button>
            </div>
          </div>
          <svg className={style.iconredWomenAggreSVG} width={569} height={546}>
            <use xlinkHref={`${sprite}#redWomenProhibition`}></use>
          </svg>
        </article>
        <div className={style.wrapperWhatAuGet}>
          <ul>
            <li>
              <h3 className={style.titleWhatAuGet}>Що ви отримаєте?</h3>
              <p className={style.textWhatAuGet}>
                <span>Ви глибше зрозумієте,</span> як з дитинства формуються та
                змінюються наші особисті кордони, як вони стають невидимими
                правилами.
              </p>
            </li>
            <li>
              <h3 className={style.titleWhatAuGet}>Що ви отримаєте?</h3>
              <p className={style.textWhatAuGet}>
                <span> Дізнаєтеся,</span> коли порушення своїх власних меж може
                сприяти розвитку і дорослішанню.
              </p>
            </li>
            <li>
              <h3 className={style.titleWhatAuGet}>Що ви отримаєте?</h3>
              <p className={style.textWhatAuGet}>
                <span>А також отримаєте знання</span> про те, як жінка з чіткими
                кордонами взаємодіє із собою та суспільством у сучасному світі.
              </p>
            </li>
          </ul>
          <p className={style.textTitleWhatAuGet}>Що ви отримаєте?</p>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={40}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          // autoHeight={true}
          className={style.mySwiperFlirtHero}
        >
          <SwiperSlide className={style.customSwiperSlide}>
            <h3 className={style.titleWhatAuGet}>Що ви отримаєте?</h3>
            <p className={style.textWhatAuGet}>
              <span>Ви глибше зрозумієте,</span> як з дитинства формуються та
              змінюються наші особисті кордони, як вони стають невидимими
              правилами.
            </p>
          </SwiperSlide>
          <SwiperSlide className={style.customSwiperSlide}>
            <h3 className={style.titleWhatAuGet}>Що ви отримаєте?</h3>
            <p className={style.textWhatAuGet}>
              <span> Дізнаєтеся,</span> коли порушення своїх власних меж може
              сприяти розвитку і дорослішанню.
            </p>
          </SwiperSlide>
          <SwiperSlide className={style.customSwiperSlide}>
            <h3 className={style.titleWhatAuGet}>Що ви отримаєте?</h3>
            <p className={style.textWhatAuGet}>
              <span>А також отримаєте знання</span> про те, як жінка з чіткими
              кордонами взаємодіє із собою та суспільством у сучасному світі.
            </p>
          </SwiperSlide>
        </Swiper>
      </div>
      <ChoiseProhibitionVideo
        isOpen={modalPrizeIsOpen}
        onClose={() => setModalPrizeOpen(false)}
      ></ChoiseProhibitionVideo>
      <MoreQuestions
        isOpen={prizeModalOpen}
        onClose={() => setPrizeModalOpen(false)}
      ></MoreQuestions>
    </section>
  );
}
