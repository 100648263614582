import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  MoreQuestions,
  JoinZoomModal,
  JoinZoomModalAlternative,
} from "../HFM/Modals/Modules";
import { ChoiseTariffModal } from "../HFM/Modals/ChoiseTariffModal";
import sprite from "../../icons.svg";
import style from "./Hero.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import About from "../About/About";
import { selectIsLoggedIn } from "../../../redux/auth/selectors";
import { useSelector } from "react-redux";
import LogIn from "../../Modals/LogIn/LogIn";
// import { Banner } from "../Banner/Banner";
// import { Banner } from "../Banner/Banner";

export default function Hero() {
  const [prizeModalOpen, setPrizeModalOpen] = useState(false);
  const [tariffModalOpen, setTariffModalOpen] = useState(false);
  const [joinZoomModalOpen, setjoinZoomModalOpen] = useState(false);
  const [joinZoomModalAlternativeOpen, setjoinZoomModalAlternativeOpen] =
    useState(false);
  const [setUserDataModalOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [modalLogInIsOpen, setModalLogIn] = useState(false);

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const openModal = (modalName) => {
    // console.log(`Opening modal: ${modalName}`);
    if (modalName === "prizeModal") {
      setPrizeModalOpen(true);
    } else if (modalName === "tariffModal") {
      setTariffModalOpen(true);
    } else if (modalName === "userDataModal") {
      setUserDataModalOpen(true);
    } else if (modalName === "joinZoomModal") {
      setjoinZoomModalOpen(true);
    } else if (modalName === "joinZoomModalAlternative") {
      setjoinZoomModalAlternativeOpen(true);
    }
  };

  const closeModal = (modalName) => {
    // console.log(`Closing modal: ${modalName}`);
    if (modalName === "prizeModal") {
      setPrizeModalOpen(false);
    } else if (modalName === "tariffModal") {
      setTariffModalOpen(false);
    } else if (modalName === "userDataModal") {
      setUserDataModalOpen(false);
    } else if (modalName === "joinZoomModal") {
      setjoinZoomModalOpen(false);
    } else if (modalName === "joinZoomModalAlternative") {
      setjoinZoomModalAlternativeOpen(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section className={style.heroSection} id="heroSection">
        <div className="container">
          {/* <Banner
            openModal={openModal}
            conferenceDate={"2024-12-05T22:00:00Z"}
          /> */}
          <article
            className={`${style.wrapperBanner} ${loaded ? style.loaded : ""}`}
          >
            <ul>
              <li>
                <p
                  className={`${style.heroBannerInfoText} ${style.heroBannerInfoTextF}`}
                >
                  online
                </p>
              </li>
              <li className={style.wrapperBannerInformation}>
                <svg
                  className={style.iconWomenPlaceBanner}
                  width={205}
                  height={197}
                >
                  <use xlinkHref={`${sprite}#redWomenCare`}></use>
                </svg>

                <p className={style.heroBannerSecondInfoText}>
                  четвертий з 11 етапів психоемоційного розвитку
                </p>
                <div className={style.wrapperPresentationTopic}>
                  <h2>
                    Турбота та здатність турбуватись <br />
                    <span className={style.mainTitleSpan}>{/*  */}</span>
                  </h2>
                </div>
                <p className={style.textAboutWomen}>
                  до жінок, біля жінок, жінками
                </p>
                <button
                  className={style.whiteBtnHero}
                  onClick={() => {
                    isLoggedIn ? openModal("tariffModal") : setModalLogIn(true);
                  }}
                >
                  Відвідати конференцію
                </button>
                <Link to="https://forms.gle/5VqRCEDD1VH2ZRpK6" target="_blank">
                  <button className={style.joinHowSpeakerBtn}>
                    Стати Спікером
                  </button>
                </Link>
              </li>
              <li>
                <p
                  className={`${style.heroBannerInfoText} ${style.heroBannerInfoTextS}`}
                >
                  20-21.01
                </p>
              </li>
            </ul>
            <p className={style.womenTextOnBanner1}>жінки</p>
            <p className={style.womenTextOnBanner2}>жінки</p>
            <p className={style.womenTextOnBanner3}>жінки</p>
            <p className={style.womenTextOnBanner4}>жінки</p>
            <p className={style.womenTextOnBanner5}>жінки</p>
            <p className={style.womenTextOnBanner6}>жінки</p>
            <p className={style.womenTextOnBanner7}>жінки</p>
            <p className={style.womenTextOnBanner8}>жінки</p>
            <p className={style.womenTextOnBanner9}>жінки</p>
            <p className={style.womenTextOnBanner10}>жінки</p>
            <p className={style.womenTextOnBanner11}>жінки</p>
            <p className={style.womenTextOnBanner12}>жінки</p>
            <p className={style.womenTextOnBanner13}>жінки</p>
            <p className={style.womenTextOnBanner14}>жінки</p>
          </article>
          <About />
          <div
            className={`${style.wrapperHeroInformation} ${
              loaded ? style.loaded : ""
            }`}
          >
            <article className={style.wrapperNextConferenceInformation}>
              <p className={style.numberOfConference}>
                четвертий з 11 етапів психоемоційного розвитку
              </p>
              <h2 className={style.titleFirstConference}>
                Турбота та здатність турбуватись <br />
                <br />
                <span className={style.mainTitleSpanS}>{/*  */}</span>
              </h2>
              <h3 className={style.titleFirstConferenceDop}>
                до жінок, біля жінок, жінками
              </h3>
              <p className={style.dateInformation}>
                20-21 ciчня 2025 <span></span> платформа зум
              </p>
              <p className={style.dateInforationMore}>
                з 10:00 до 19:30 за київським часом - перший день <br />
                з 10:00 до 20:15 за київським часом - другий день <br />
                <span>обідня перерва з 14:30 до 15:30</span>
              </p>
              <div className={style.wrapperButtonFirstConference}>
                <button
                  className="redBtn"
                  onClick={() => {
                    isLoggedIn ? openModal("tariffModal") : setModalLogIn(true);
                  }}
                >
                  Відвідати конференцію
                </button>
                <button
                  className="transperentBtn"
                  onClick={() => openModal("prizeModal")}
                >
                  Додаткові питання
                </button>
              </div>
            </article>
            <svg className={style.iconRedWomenSVG} width={569} height={546}>
              <use xlinkHref={`${sprite}#redWomenCare`}></use>
            </svg>
          </div>
          <div className={style.wrapperInfoAboutConference}>
            <ul>
              <li>
                <p className={style.textInfoAboutConferene}>
                  <span>
                    Чи замислювалися ви, що турбота — це більше, ніж просто дія?
                  </span>{" "}
                  Це основа зрілих стосунків, здатність будувати близькість і
                  бачити радість чи біль іншого як частину свого власного світу.
                  Але що робить турботу зрілою, і чому її неправильне вираження
                  може стати перешкодою для щастя?
                </p>
                <p className={style.textInfoAboutConferene}>
                  На конференції <span>ми зануримося</span> в розуміння турботи
                  як фундаментальної потреби кожного з нас. З дитинства це те,
                  що допомогло нам вижити у нашій безпорадності. Але коли ми
                  дорослішаємо, турбота змінює свою природу — ми вчимося дбати
                  не лише про себе, а й про інших.
                </p>
                <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
              </li>
              <li>
                <p className={style.textInfoAboutConferene}>
                  <span>Що вас очікує?</span> <br /> - Турбота як шлях до
                  зрілості у стосунках. - Як вона формує наше вміння любити й
                  бути близькими, долати йегоцентризм та розвивати емпатію.
                </p>
                <p className={style.textInfoAboutConferene}>
                  - Почуття провини та жалю. Розуміння, як ці емоції допомагають
                  нам зберегти баланс у взаєминах та дотримуватися
                  загальнолюдських принципів.
                </p>
                <p className={style.textInfoAboutConferene}>
                  - Залежність та незалежність. Чому бажання турбуватися лише
                  про себе є ознакою незрілості, і як знайти баланс між
                  піклуванням про себе та інших.
                </p>
                <p className={style.textInfoAboutConferene}>
                  - Роль турботи у стосунках. Як вона зміцнює зв&apos;язок між
                  людьми, формує близькість і справжню любов.
                </p>
              </li>

              <li>
                <p className={style.textInfoAboutConferene}>
                  <span>Чому це важливо для вас?</span> <br /> Ця конференція —
                  це можливість побачити свої стосунки під новим кутом. Ви
                  навчитеся розпізнавати зріллу турботу, а також ідентифікувати
                  моменти, коли ваші власні кордони або потреби інших людей
                  порушуються.
                </p>
                <p className={style.textInfoAboutConferene}>
                  <span>Приєднуйтесь, якщо ви:</span>
                  <br />- Хочете глибше зрозуміти, як розвивати зрілість у
                  стосунках.
                  <br />- Шукайте відповіді на питання про баланс між турботою
                  про себе та про інших.
                  <br />- Прагнете створити теплі, здорові та довірливі
                  стосунки.
                </p>
                <p className={style.textInfoAboutConferene}>
                  Турбота — це не лише те, що ми отримуємо від інших, але й те,
                  що ми даруємо.{" "}
                  <span>
                    Давайте разом дослідимо, як це після цього жити повноцінним,
                    автентичним життям.
                  </span>
                </p>
              </li>
            </ul>
            <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={40}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            autoHeight={true}
            className={style.mySwiperHeroInfo}
          >
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>Турбота та здатність турбуватись</span>
              </p>
              <p className={style.textInfoAboutConferene}>
                <span>
                  Чи замислювалися ви, що турбота — це більше, ніж просто дія?
                </span>{" "}
                Це основа зрілих стосунків, здатність будувати близькість і
                бачити радість чи біль іншого як частину свого власного світу.
                Але що робить турботу зрілою, і чому її неправильне вираження
                може стати перешкодою для щастя?
              </p>

              <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                На конференції <span>ми зануримося</span> в розуміння турботи як
                фундаментальної потреби кожного з нас. З дитинства це те, що
                допомогло нам вижити у нашій безпорадності. Але коли ми
                дорослішаємо, турбота змінює свою природу — ми вчимося дбати не
                лише про себе, а й про інших.
              </p>
              <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>Що вас очікує?</span> <br /> - Турбота як шлях до зрілості
                у стосунках. - Як вона формує наше вміння любити й бути
                близькими, долати йегоцентризм та розвивати емпатію.
              </p>
              <p className={style.textInfoAboutConferene}>
                - Почуття провини та жалю. Розуміння, як ці емоції допомагають
                нам зберегти баланс у взаєминах та дотримуватися
                загальнолюдських принципів.
              </p>

              <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>Що вас очікує?</span>
              </p>

              <p className={style.textInfoAboutConferene}>
                - Залежність та незалежність. Чому бажання турбуватися лише про
                себе є ознакою незрілості, і як знайти баланс між піклуванням
                про себе та інших.
              </p>
              <p className={style.textInfoAboutConferene}>
                - Роль турботи у стосунках. Як вона зміцнює зв&apos;язок між
                людьми, формує близькість і справжню любов.
              </p>
              <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>Чому це важливо для вас?</span> <br /> Ця конференція — це
                можливість побачити свої стосунки під новим кутом. Ви навчитеся
                розпізнавати зріллу турботу, а також ідентифікувати моменти,
                коли ваші власні кордони або потреби інших людей порушуються.
              </p>

              <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>Приєднуйтесь, якщо ви:</span>
                <br />- Хочете глибше зрозуміти, як розвивати зрілість у
                стосунках.
                <br />- Шукайте відповіді на питання про баланс між турботою про
                себе та про інших.
                <br />- Прагнете створити теплі, здорові та довірливі стосунки.
              </p>
              <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                Турбота — це не лише те, що ми отримуємо від інших, але й те, що
                ми даруємо.{" "}
                <span>
                  Давайте разом дослідимо, як це після цього жити повноцінним,
                  автентичним життям.
                </span>
                <p className={style.aboutFreePlace}>Залишилось 400 місць</p>
              </p>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <MoreQuestions
        isOpen={prizeModalOpen}
        onClose={() => closeModal("prizeModal")}
      ></MoreQuestions>
      <ChoiseTariffModal
        isOpen={tariffModalOpen}
        onClose={() => closeModal("tariffModal")}
      ></ChoiseTariffModal>

      <JoinZoomModal
        isOpen={joinZoomModalOpen}
        onClose={() => closeModal("joinZoomModal")}
        openModal={openModal}
      />
      <LogIn
        isOpen={modalLogInIsOpen}
        onClose={() => setModalLogIn(false)}
      ></LogIn>
      <JoinZoomModalAlternative
        isOpen={joinZoomModalAlternativeOpen}
        onClose={() => closeModal("joinZoomModalAlternative")}
        openModal={openModal}
      />
    </>
  );
}
