import style from "./FlirtReviews.module.css";

import { Scrollbar, Virtual } from "swiper/modules";
import { useState, useEffect } from "react";

import "swiper/css";
import "swiper/css/scrollbar";
import { SwiperSlide, Swiper } from "swiper/react";

export default function FlirtReviews() {
  const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView());

  useEffect(() => {
    function handleResize() {
      setSlidesPerView(getSlidesPerView());
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function getSlidesPerView() {
    return window.innerWidth >= 455 ? 2 : 1;
  }

  const flirtReviewsJSON = [
    {
      reviewerName: "Ірина Дубова",
      reviewerComment:
        "Дякую. 😊 Была оба дня. Иногда , жаль, приходилось отвлекаться. Очень ценная информация!!! Особенно, когда такие разные спикеры, с разных сторон подсвечивают одну тему - это супер! Настолько комплексно и многообразно 👍 как через калейдоскоп была возможность прожить себя, свои ощущения, сопротивления, ограничения в заданной теме и с другой стороны подсветить и открыть новые направления, куда направить внимание 😊 Благодарю! ❤️❤️ и прежде всего , конечно, организатора Анастасию Матюшевскую за идею и такое созвездие мастеров ❤️",
    },
    {
      reviewerName: "Ольга Горішня",
      reviewerComment:
        "Енергетика - ВОГНИЩЕЕ!!!🔥🔥🔥 Заслуховуюсь, не моргаючи навіть))) Захоплююсь лектором!!! Жива, справжня, емоційна…😍🤩❤️‍🔥",
    },
    {
      reviewerName: "iPhone (Helen) (2)",
      reviewerComment:
        "Не дарма прийшла на конференцію заради цього виступу. Юля неймовірна.",
    },
    {
      reviewerName: "Svitlana Benedychuk",
      reviewerComment:
        "Дякую, Натуся, за твою ідентичність, і що чітко доносиш меседж, що це не дорівнює шароварщині.",
    },
    {
      reviewerName: "Maria Minzhyrian",
      reviewerComment: "Велика вдячність, дуже надихнули 🫶🏼",
    },
    {
      reviewerName: "Оксана Козлова",
      reviewerComment:
        "Наталя, після твоєї доповіді не тільки інсайти, а ще й сльози радості, мурахи та план до дій. Дякую за цінну інформацію 🥰",
    },
    {
      reviewerName: "Алина",
      reviewerComment:
        "Очень классная тема, берет гордость что я украинка! Мы лучшие во всём!",
    },
    {
      reviewerName: "Angelina",
      reviewerComment:
        "Наталия, спасибо, вы вдохновляете на любовь и гордость к своей идентичности. В этом появляется так много вдохновения и энергии, хочется это поддерживать и передавать.",
    },
    {
      reviewerName: "Оксана Козлова",
      reviewerComment:
        "Світлана, як завжди в саму глибину. Дякую за вашу лекцію, на ній було цікаво, комфортно і тепло, хоч теми болючі підіймались❤️",
    },
    {
      reviewerName: "Svitlana Kolisnyk",
      reviewerComment:
        "Світлана, дякую, це було дуже глибоко і ясно. Особливо про «час лікує», це інтроект з мого життя, а лікує досвід❤️",
    },
    {
      reviewerName: "Kateryna Malynka",
      reviewerComment:
        "Лекції, як терапія виходять… Кожного разу щось відкривається) ДЯКУЮ!",
    },
    {
      reviewerName: "Ольга Горішня",
      reviewerComment:
        "Огромное спасибо за разбор! Многое законспектировала и себе!!! 🙏🏻🙏🏻🙏🏻❤️💛💙",
    },
    {
      reviewerName: "Пользователь Zoom",
      reviewerComment:
        "Отклик 100% - вы попали очень точно и глубоко!!! Согласна на миллион! Обратная связь - теперь хочу к вам тоже на разбор)))",
    },
    {
      reviewerName: "Svitlana Kolisnyk",
      reviewerComment: "Анастасія і вся твоя команда - дякую!",
    },
  ];
  return (
    <section className={style.sectionReviews} id="sectionReviews">
      <div className="container">
        <h2 className={style.titleReviews}>/відгуки учасників</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={slidesPerView}
          modules={[Scrollbar, Virtual]}
          scrollbar={{
            draggable: true,
          }}
          autoHeight={true}
          className={`${style.mySwiperReviews} ${style.reset}`}
        >
          {flirtReviewsJSON.map((aggreReviewsJSON, index) => (
            <SwiperSlide key={index} className="SwiperSlideReviewsFlirt">
              <h3 className={style.titleNameReviewe}>
                {aggreReviewsJSON.reviewerName}
              </h3>
              <p className={style.commentFromReviewer}>
                {aggreReviewsJSON.reviewerComment}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
